import createAction from '../../common/services/create-action';

export const CREATE_CATEGORY_REQUEST = 'category/CREATE_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'category/CREATE_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'category/CREATE_FAILURE';

export const createCategoryRequest = createAction(CREATE_CATEGORY_REQUEST);
export const createCategorySuccess = createAction(CREATE_CATEGORY_SUCCESS);
export const createCategoryFailure = createAction(CREATE_CATEGORY_FAILURE);

export function createCategory(category = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(createCategoryRequest());

    const promise = request.post(`/categories/`, category, { noRetriesOnErrors: [503, 504] });

    return promise
      .then(
        category => dispatch(createCategorySuccess(category)),
        () => dispatch(createCategoryFailure()),
      )
      .then(() => promise);
  };
}
