import { navigateWithinForum } from '../../../../common/actions/navigate-within-forum';

export const discardPostModalResolver = ({
  categorySlug,
  postSlug,
  shouldRedirect,
}) => dispatch => {
  if (shouldRedirect) {
    return dispatch(
      navigateWithinForum(categorySlug ? `/${categorySlug}${postSlug ? `/${postSlug}` : ''}` : '/'),
    );
  }
};
