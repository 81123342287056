const LNG_KEY = getKey('language');
const STYLE_PRESET_KEY = getKey('style_preset');
const STYLE_OVERRIDE_KEY = getKey('style_override');

export const EMPTY_VALUE = '---';

export const initDevToolsStore = platformAPIs => {
  let onChangeCallback = () => {};

  const getState = () => ({
    language: getFromLocalStorage(platformAPIs, LNG_KEY),
    stylePreset: getFromLocalStorage(platformAPIs, STYLE_PRESET_KEY),
    styleOverrides: getFromLocalStorage(platformAPIs, STYLE_OVERRIDE_KEY),
  });

  const persist = (k, v) => {
    saveInLocalStorage(platformAPIs, k, v === EMPTY_VALUE ? null : v);
    onChangeCallback(getApi());
  };

  const getApi = () => ({
    setLanguage: lng => persist(LNG_KEY, lng),
    setStylePreset: preset => persist(STYLE_PRESET_KEY, preset),
    reset: () => {
      persist(LNG_KEY, null);
      persist(STYLE_PRESET_KEY, null);
      persist(STYLE_OVERRIDE_KEY, null);
    },
    state: getState(),

    onChange: callback => {
      onChangeCallback = callback;
    },
    updateStyles: styles => {
      persist(STYLE_OVERRIDE_KEY, styles);
    },
    resetStyles: () => persist(STYLE_OVERRIDE_KEY, null),
  });

  return getApi();
};

function getFromLocalStorage(platformAPIs, key) {
  try {
    const item = platformAPIs.storage.local.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  } catch (error) {
    return null;
  }
}

function saveInLocalStorage(platformAPIs, key, value) {
  try {
    return platformAPIs.storage.local.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.warn(error);
  }
}

function getKey(key) {
  return `__frmDevPanel-${key}`;
}
